<div class="container">
    <div class="row" style="margin-top: 2rem;">
        <div class="col-md-12">
            <h3>{{category && category.attributes.name}}</h3>
        </div>
        <div class="col-12 col-sm-12 p-0 m-0" >
            <div class="container-fluid" style="margin-top:2rem;">
                <div class="row">
                    <a class="col-md-6 news "  *ngFor="let item of pages?.data">
                        <div class="container-fluid" routerLink="/page/{{item.id}}">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" [src]="item.cover"></div>
                                <div class="col-md-6">
                                    <label>{{item.attributes.published}}</label><br>
                                    <label>{{category && category.attributes.name}}</label>
                                    <p class="title">{{item.attributes.title}}</p>
                                    <p>{{item.attributes.short_description}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-end" style="margin-top: 2vh;">
        <p-paginator 
            [rows]="1" 
            [totalRecords]="numberOfPages"
            (onPageChange)="paginate($event)"
        >
        </p-paginator>
    </div>
</div>