<div class="container">
    <div class="row" style="margin-top: 2rem;">
        <div class="col-md-12" style="text-align: center;">
            <h2 translate>contact2</h2>
            <p translate> contacttip</p>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-8"
            style="display: flex;justify-content: space-between;align-items: center;margin-top: 2rem;">
            <div class="container">
                <div class="row">

                    <div class="single_address col-md-4">
                        <i class="material-icons">pin_drop</i>
                        <h4 translate>offices</h4>
                        <p style="color:#475467" translate>visit</p>
                        <p style="color:#1849A9;font-weight: 600;" translate>address</p>
                    </div>
                    <div class="single_address col-md-4">
                        <i class="material-icons">alternate_email</i>
                        <h4>Email</h4>
                        <p style="color:#475467" translate>direct</p>
                        <p style="color:#1849A9;font-weight: 600">eveflo@otenet.gr</p>
                    </div>
                    <div class="single_address col-md-4">
                        <i class="material-icons">perm_phone_msg</i>
                        <h4 translate>phone</h4>
                        <p style="color:#475467" translate>hours</p>
                        <p style="color:#1849A9;font-weight: 600">2385022334</p>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row" style="margin-top: 4rem;">
        <div class="col-md-12" style="text-align: center;">
            <h2 translate>form</h2>
            <p translate> cdetails</p>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <form class="form" #form="ngForm">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6" style="margin-top: 1rem;">
                            <label for="name" translate>name</label>
                            <input type="text" name="name" class="form-control" id="name" required="required" ngModel>
                        </div>
                        <div class="col-md-6" style="margin-top: 1rem;">
                            <label for="lastname" translate>lastname</label>
                            <input type="text" name="lastname" class="form-control" id="lastname" required="required"
                                ngModel>
                        </div>
                        <div class="col-md-12" style="margin-top: 1rem;">
                            <label for="email">Email</label>
                            <input type="email" name="email" class="form-control" required="required" ngModel>
                        </div>
                        <div class="col-md-12" style="margin-top: 1rem;">
                            <label for="message" translate>message</label>
                            <textarea rows="6" name="message" class="form-control" required="required"
                                ngModel></textarea>
                        </div>
                        <div class="col-md-12" style="margin-top: 1rem;">
                            <ngx-recaptcha2 #captchaElem
                            [siteKey]="sitekey"
                            (reset)="handleReset()"
                            (expire)="handleExpire()"
                            (load)="handleLoad()"
                            (success)="handleSuccess($event)"
                            [useGlobalDomain]="false"
                            [size]="size"
                            [hl]="lang"
                            [theme]="theme"
                            [type]="type"
                            name="captcha"
                            ngModel>
                          </ngx-recaptcha2>
                        </div>
                        <div class="col-md-12" style="margin-top: 1rem;">
                        <button class="button" translate>send</button></div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>