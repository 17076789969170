<div class="container" style="margin-top: 2rem;background:#0d101d;padding: 3rem;">
    <div class="row">
        <div class="col-md-4">
            <img class="img-fluid" src="/assets/Union(1).png">
            <p style="color: #EAECF0;" translate>offcial</p>
        </div>
        <div class="col-md-2">
            <h6 style="color: #EAECF0;" translate>links</h6>
            <ul class="list-unstyled">
                <li>
                  <a routerLink="/page/{{links.attributes && links.attributes.oroi_xrisis}}" translate>terms</a>
                </li>
                <li>
                  <a  routerLink="/page/{{links.attributes && links.attributes.privacy_policy}}" translate>privacypolicy</a>
                </li>
                <li>
                  <a routerLink="/page/{{links.attributes && links.attributes.dilosi_prosvasimotitas}}" translate>accesibility</a>
                </li>
                <li>
                  <a routerLink="/contact" translate>contact</a>
                </li>
              </ul>
        </div>
        <div class="col-md-2">
            <h6 style="color: #EAECF0;" translate>followus</h6>
            <ul class="list-unstyled">
                <li>
                  <a href="https://www.facebook.com/epimelitirioflorinas/?locale=el_GR"><img class="img-fluid" src="/assets/Facebook.png" style="margin-right: 0.5rem;">Facebook</a>
                </li>
                <li>
                  <a href="#!"><img class="img-fluid" src="/assets/twitter.png" style="margin-right: 0.5rem;">Twitter</a>
                </li>
              </ul>
        </div>
        <div class="col-md-4">
            <h6 style="color: #EAECF0;">Newsletter</h6>
            <form class="form-inline" style="display: flex;">
                <input class="form-control" type="search" placeholder="Email">
                <button class="btn btn-primary my-2 my-sm-0" type="submit" style="margin-left: 0.5rem;">Εγγραφή</button>
              </form>
        </div>
       
    </div>
    <div class="row" style="border-top: 1px solid var(--gray-600, #475467);margin-top: 1rem;">
        <div class="col-md-4" style="margin-top: 2rem;color: #EAECF0;">© {{year}} Florina. All rights reserved.</div>
        <div class="col-md-5" ></div>
        <div class="col-md-3" style="margin-top: 2rem;color: #EAECF0;display: flex;"><a href="https://www.sga.gr/" target="_blank">Web Design & Development SGA</a> </div>
    </div>
</div>