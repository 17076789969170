import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { CategoryComponent } from './category/category.component';
import { ContactComponent } from './contact/contact.component';
import { PageComponent } from './page/page.component';


const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: 'category/:id/:page',
    component: CategoryComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'page/:id',
    component: PageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',useHash: true,scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
