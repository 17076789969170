import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  constructor(private _Activatedroute: ActivatedRoute,
    private api: ApiService,
   
     private router: Router) { }

     id: any
     category: any
     pages: any
     apiurl = environment.apiUrlPublic
     currentPages: any[] = [];
     page: any
     first;
     dataPerPage: number = 10; //for each page, the number of data (in our case data=pages from strapi) to be displayed is 10
     totalRecords: number; //the number of total strapi-pages that contain in title or body one or more words that user searched
     numberOfPages: number; //the number of paginator pages. For example, if we have 20 data (strapi-pages) and we want to display 10 data
     @ViewChild('paginator', { static: false }) public paginator: Paginator;
   
     async ngOnInit() {
   
       this._Activatedroute.params.subscribe(async params => {
         // Defaults to 0 if no query param provided.
         this.id = params['id'] || 0;
         this.page = params['page'] || 1;
         this.pages = await this.fetchElements(this.id, 10, 'el');
         // console.log(this.pages)
         let data: any = await this.api.getCategory(this.id).toPromise()
         this.totalRecords = this.pages.meta.pagination.total;
         this.numberOfPages = Math.ceil(this.totalRecords / this.dataPerPage);
         this.category = data.data
   
         // this.translateCookies();
   
         //react to the language changing event
         // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
         //   this.translateCookies();
         // })
       });
   
   
     }
   
     async fetchElements(id: String, limit, locale): Promise<any> {
   
       let pages: any = await this.api.getCategory2(id, limit, locale, this.page).toPromise()
       this.first = this.page - 1;
       pages.data.forEach((element: any) => {
   
         var date2 = new Date(element.attributes.published);
         var n = date2.toLocaleDateString('el-GR', { timeZone: 'Europe/Athens' })
         element.attributes.published = n
         element.attributes.title = element.attributes.title.substring(0,100)
         element.attributes.title = `${element.attributes.title}...`
   
         if (element.attributes.cover.data) {
           element.cover = `${this.apiurl}${element.attributes.cover.data.attributes.url}`
         } else {
           element.cover = `/assets/CoverFlorina.jpg`
         }
   
         if (element.attributes.temporary == true) {
           // console.log(element.attributes.date_from)
           var currentDate = new Date().toISOString()
           var from = new Date(element.attributes.date_from).toISOString();
           var to = new Date(element.attributes.date_to).toISOString();
   
           // console.log(currentDate > from && currentDate < to);
           if (currentDate > from && currentDate < to) {
             element.attributes.post = true
           } else {
             element.attributes.post = false
           }
   
         } else {
           element.attributes.post = true
         }
       });
       return pages;
     }
   
     // translateCookies() {
     //   this.translate//
     //     .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
     //     .subscribe(data => {
   
     //       this.ccService.getConfig().content = this.ccService.getConfig().content || {};
     //       // Override default messages with the translated ones
     //       this.ccService.getConfig().content.header = data['cookie.header'];
     //       this.ccService.getConfig().content.message = data['cookie.message'];
     //       this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
     //       this.ccService.getConfig().content.allow = data['cookie.allow'];
     //       this.ccService.getConfig().content.deny = data['cookie.deny'];
     //       this.ccService.getConfig().content.link = data['cookie.link'];
     //       this.ccService.getConfig().content.policy = data['cookie.policy'];
   
     //       this.ccService.destroy(); // remove previous cookie bar (with default messages)
     //       this.ccService.init(this.ccService.getConfig()); // update config with translated messages
     //     });
     // }
   
     async paginate(event: any) {
       this.router.navigate([`/category/${this.id}/${event.page + 1}`]);
       this.page = event.page + 1
       this.pages = await this.fetchElements(this.id, 10, 'el');
     }
   
}
