<div class="container">
    <div class="row" style=" background-color: #00359E;">
        <div class="cover col-5 col-sm-5" style="display: flex;justify-content: center;align-items:center">
            <div style="padding-left: 3rem;color: #f8f8f8;font-weight: bold;">
                <p style="margin-bottom:0px;font-size: 1rem;color: #f8f8f8 !important" translate>welcome</p>
                <h1 class="h1" translate>chamber</h1>
                <a class="buttonBlue" style="padding: 0.8rem;text-decoration: none;font-weight: bold;"
                    href="https://echamber.ebef.gr/" target="blank" translate>echamber</a>
            </div>
        </div>
        <div class="bb col-7 col-sm-7">
            <img class="img-fluid" src="/assets/Image.png" style="margin-top: 4rem;margin-bottom: 1rem;
            height: 436px;
            width: 379px;
            margin-right: 3rem;
            border-radius: 160px 0px; ">
        </div>
    </div>
    <div class="row" style="margin-top: 2rem;">
        <div class="pd col-12 col-md-3 p-0 m-0">
            <h3 translate>ch</h3>
            <p translate>first</p>
            <p translate>second</p>
            <a routerLink="/page/1" translate>more</a>
            <div>
                <img class="img-fluid" src="/assets/logo2.png">
            </div>

        </div>
        <div class="col-12 col-md-9 p-0 m-0">
            <div class="container-fluid">
                <div class="row" style="justify-content: end;align-items:center;">
                    <a class="col-md-6 services" href="https://echamber.ebef.gr/">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-2"><img class="img-fluid" src="/assets/Icon.png"></div>
                                <div class="col-md-10">
                                    <p class="title" translate>onlineservices</p>
                                    <p translate>servicesDesc</p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a class="col-md-6 services" href="#">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-2"><img class="img-fluid" src="/assets/Icon(1).png"></div>
                                <div class="col-md-10">
                                    <p class="title" translate>digital</p>
                                    <p translate>digitaldesc</p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a class="col-md-6 services" href="https://www.businessportal.gr/" target="_blank">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-2"><img class="img-fluid" src="/assets/Icon(2).png"></div>
                                <div class="col-md-10">
                                    <p class="title" translate>gemi</p>
                                    <p translate>gemidesc </p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a class="col-md-6 services" href="https://eyms.businessportal.gr/auth" target="_blank">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-2"><img class="img-fluid" src="/assets/Icon(3).png"></div>
                                <div class="col-md-10">
                                    <p class="title" translate>onestop</p>
                                    <p translate>onestopdesc</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="background: #F9FAFB;margin-top: 1rem;padding: 1rem;">
        <div class="col-12 col-sm-12 p-0 m-0">
            <h3 translate>latestnews</h3>
            <p translate>newsdesc</p>
        </div>
        <div class="col-12 col-sm-12 p-0 m-0">
            <div class="container-fluid">
                <div class="row" >
                    <a class="col-md-6 news " routerLink="/page/{{item.id}}" *ngFor="let item of latestNews">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" [src]="item.cover"></div>
                                <div class="col-md-6">
                                    <label>{{item.attributes.published}}</label>
                                    <p class="title">{{item.attributes.title}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <!-- <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(1).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">Τέλος στη χρήση των πλαστικών με τον Νόμο 4736/2020</p>
                                    <p>Από 1ης Ιανουαρίου 2022, επιβάλλεται ως περιβαλλοντικό τέλος 0,04 λεπτά ανά τεμάχιο σε πάσης φύσεως πλαστικές...</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(2).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">Χρηματοδότηση από το επόμενο ΕΣΠΑ του Μηχανισμού Διάγνωσης Αναγκών της αγοράς εργασίας της ΠΚΜ</p>
                                    <p>Τη διασφάλιση της χρηματοδότησης του Μηχανισμού Διάγνωσης...</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(3).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">Ηλεκτρονικό Ράφι Online Πωλήσεων στην Τοπική Ηλεκτρονική Αγορά του Νομού Φλώρινας και στην Πανελλήνια Ηλεκτρονική Αγορά www.directmarket.gr</p>
                                    <p>Αποκτήστε Ηλεκτρονικό Ράφι Online Πωλήσεων...</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(3).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">Τέλος στη χρήση των πλαστικών με τον Νόμο 4736/2020</p>
                                    <p>Από 1ης Ιανουαρίου 2022, επιβάλλεται ως περιβαλλοντικό τέλος 0,04 λεπτά ανά τεμάχιο σε πάσης φύσεως πλαστικές...</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(2).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">17η Διεθνής Έκθεση «ΑGRICULTURE AND LIVESTOCK EXHIBITION» στη Σμύρνη στις 1-4 Φεβρουαρίου 2022</p>
                                    <p>Κατά τις ημερομηνίες 2-6 Φεβρουαρίου 2022 πρόκειται να πραγματοποιηθεί η ...</p>
                                </div>
                            </div>
                        </div>
                    </a> -->
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 2rem;">
        <h3 style="text-align: center;" translate>freeServices</h3>
        <p-carousel [value]="images2" styleClass="custom-carousel" [numVisible]="4" [numScroll]="2"
        [circular]="true" [autoplayInterval]="4000" [responsiveOptions]="responsiveOptions">
        <ng-template let-product pTemplate="item">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <a [href]="product.attributes.url" target="_blank">
                            <img [src]="product.attributes.banner.data.attributes.url"
                                [alt]="product.attributes.banner.data.attributes.name" class="img-fluid" style=" float: left;
                                width:  250px;
                                height: 150px;
                                object-fit: contain;">
                        </a>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>
    </div>
</div>

  <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" translate>{{popupData.attributes.title}}</h4>
            <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="margin-top: 3vh;" [innerHTML]="body"></div>
        </div>
    </ng-template>