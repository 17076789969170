import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  token: string | undefined;
  recaptchaValid: boolean;
  theme = 'light'
  type = 'image'
  lang = 'el'
  size = 'normal'
  sitekey = environment.sitekey

  constructor() {
    this.token = undefined;
    this.recaptchaValid = false;
  }

  ngOnInit(): void {
  }

  statusChanged(event: any) {
    //This function runs every time the value of the captcha changes.
    console.log(event)
    if (event === null) {
      //captcha expired
      this.recaptchaValid = false;
    } else {
      //user clicked the captcha. Captcha is now valid
      this.recaptchaValid = true;
    }
  }
  handleSuccess(event) {
    console.log('success', event)
  }

  handleLoad() {

  }
  handleExpire() {

  }
  handleReset() {

  }

}
