import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private api: ApiService) { }
  
  year = new Date().getFullYear()
  links: any = []

  async ngOnInit() {

    let links: any = await this.api.getLinks('el').toPromise();
    this.links = links.data;
  }

}
