import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
})
export class HomepageComponent implements OnInit {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '360px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }
  responsiveOptions: any;
  latestNews: any[] = [];
  apiurl = environment.apiUrlPublic;
  images2: any[] = []; //carousel at the middle part of page. Display free business services
  popupData: any;
  body: any;
  @ViewChild('content', { static: true }) public content: ElementRef;

  async ngOnInit() {
    let language = this.translate.currentLang;
    if (this.translate.currentLang == undefined) {
      language = 'el';
    }

    let latestNews: any = await this.api
      .getCategory2('1', 6, language, 1)
      .toPromise();
    this.latestNews = latestNews.data;
    for (let index = 0; index < this.latestNews.length; index++) {
      const element = this.latestNews[index];
      var date = new Date(element.attributes.published);
      var n = date.toLocaleDateString();
      element.attributes.title = element.attributes.title.substring(0, 100);
      element.attributes.title = `${element.attributes.title}...`;
      element.attributes.published = n;
      if (element.attributes.cover.data) {
        element.cover = `${this.apiurl}${element.attributes.cover.data.attributes.url}`;
      } else {
        element.cover = `/assets/CoverFlorina.jpg`;
      }
    }

    let banners: any = await this.api.getBanners().toPromise();
    this.images2 = banners.data;
    this.images2.forEach(
      (element: {
        attributes: { banner: { data: { attributes: { url: string } } } };
      }) => {
        element.attributes.banner.data.attributes.url = `${this.apiurl}${element.attributes.banner.data.attributes.url}`;
      }
    );
    this.images2.sort((a, b) =>
      a.attributes.rank > b.attributes.rank
        ? 1
        : b.attributes.rank > a.attributes.rank
        ? -1
        : 0
    );

    let popup: any = await this.api.gePopup(language).toPromise();
    this.popupData = popup.data;

    let parser = new DOMParser();
    let doc: any = parser.parseFromString(
      this.popupData.attributes.content,
      'text/html'
    );
    let images = doc.getElementsByTagName('img');

    for (let i = 0; i < images.length; i++) {
      images[i].classList.add('img-fluid');
    }

    let tables = doc.getElementsByTagName('table');

    for (let i = 0; i < tables.length; i++) {
      tables[i].style.all = 'initial';
      tables[i].classList.add('table');
      tables[i].parentNode.classList.add('table-responsive');
    }

    this.body = new XMLSerializer().serializeToString(doc);
    if (this.popupData.attributes.title !== null) {
      this.modalService.open(this.content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
      });
    }

    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      let latestNews: any = await this.api
        .getCategory2('1', 6, event.lang, 1)
        .toPromise();
      this.latestNews = latestNews.data;
      for (let index = 0; index < this.latestNews.length; index++) {
        const element = this.latestNews[index];
        var date = new Date(element.attributes.published);
        var n = date.toLocaleDateString();
        element.attributes.title = element.attributes.title.substring(0, 100);
        element.attributes.title = `${element.attributes.title}...`;
        element.attributes.published = n;
        if (element.attributes.cover.data) {
          element.cover = `${this.apiurl}${element.attributes.cover.data.attributes.url}`;
        } else {
          element.cover = `/assets/CoverFlorina.jpg`;
        }
      }
    });
  }
}
